import React from "react";
import bioPhoto1 from "../../media/img/bioPhotos/MM.png";
import bioPhoto2 from "../../media/img/bioPhotos/FR.png";
import bioPhoto3 from "../../media/img/bioPhotos/LL.png";
import bioPhoto4 from "../../media/img/bioPhotos/HB.png";
import bioPhoto5 from "../../media/img/bioPhotos/SM.png";
import bioPhoto6 from "../../media/img/bioPhotos/SL.png";

const About = () => {
  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      h1: "A Team of Dedicated and Independent Experts",
      p: "An experienced team of ​business and technology consultants, bringing the right sector, functional skills and M&A experience with an attitude of doing not talking.",

      teamMembers: [
        {
          member: {
            name: "Muriel McGrath",
            title: "President, ​Corporate Director & Certified Digital Transformation Expert| M&A Performance & Optimization",
            description:
              "Strategic Advisor on multi-boards and founder and leader of www.mc2consilium.ca, delivering M&A, business and technology insight and transformation. Pioneering “out-of-the-box” executive board director, consultant with excellent strategic thinking skills with evident talent to integrate different perspectives, to provide actionable insights & business recommendations. Global multi-cultural leader with experience in 6 industries and in IT & business consulting services. Through her past partner experiences at IBM, KPMG and CGI, she has built a reputation as a seasoned leader and a respected thought leader in technology.",
          },
        },
        {
          member: {
            name: "Stéphanie Levert",
            title: "Executive Consultant | Business Consultant & Lawyer",
            description:
              "Business consultant and lawyer recognized for her M&A experience, change management, strategic planning, corporate governance knowledge, and privacy and innovation protection focus. 20+ years in corporate executive functions in a global technology and a medical imaging software provider as well as international experience across 4 continents. Trusted influencer and risk adviser that supports growth and generates confidence in enterprise value by driving sustainable risk-mitigation actions and legal investments, and providing holistic view on deal readiness. Adaptable and flexible, fluent in French, English and Spanish.",
          },
        },
        {
          member: {
            name: "Harry Bolner",
            title: "Senior IT Consultant | M&A Technology Advisor",
            description:
              "Harry is a versatile, results-driven IT Executive/ Consultant/ Project Director and Manager/ Entrepreneur with 35+ years generating and successfully completing over US$200 million in projects for the financial, healthcare, biotechnology, telecom, manufacturing, mining, technology and retail industries worldwide (North America, Europe, Central America and Africa). Harry occupied senior management positions in the IT industry with small, medium as well large firms. With a solid multifunctional experience, Harry brings innovative and creative solutions in his projects to improve efficiency, cost, meet deadlines and exceed business objectives. Harry is fluent in 5 languages and holds an electrical engineering designation.",
          },
        },
        {
          member: {
            name: "Fina Rajerison",
            title: "Analyst and PCO | M&A Project coordination",
            description:
              "Fearless organizer with a sense for detail and analysis. Fina manages teams and projects. Clients give Kudos to Fina for her high level of professional ethics and commitment to meeting objectives. Possessing several years of online services experience with international partners (France, Thailand, Russia, Canada and the UK). Fina holds a Master's degree in Finances and Marketing. Knowing her is adopting her!",
          },
        },
        {
          member: {
            name: "Line Landry",
            title: "Executive Consultant | M&A due diligence Synergies & Optimization",
            description:
              "20+ years of experience of strategic advisory and finance in North America, Europe and, Africa. Gritty yet agile leader, with a high bias for action and building alignment around a vision. Ultimately, passionate about building long-lasting relationships. Occupied senior management and executive positions with small, medium as well large firms.",
          },
        },
        {
          member: {
            name: "Sylvia Morin",
            title: "Executive Consultant | M&A Communication & Branding",
            description:
              "Corporate director and consultant with a wealth of experience in or with public and private companies working in the consumer products, media, telecommunications and technology sectors. Her professional roadmap as strategist and strategic communicator has given her a deep knowledge of the challenges surrounding change management and mergers & acquisitions, as well as the dynamics of management, governance and succession in companies under family control.",
          },
        },
      ],
    },
    french: {
      h1: "Une équipe d'experts dévoués et indépendants",
      p: "Une équipe expérimentée de consultants en affaires et en technologie, réunissant le bon secteur, les compétences fonctionnelles et l'expérience en matière de fusions-acquisitions, privilégiant l'action à la parole.",

      teamMembers: [
        {
          member: {
            name: "Muriel McGrath",
            title: "Présidente, ​ Administratrice de Sociétés & Experte Certifiée en Transformation Numérique | Performance et Optimisation F&A",
            description:
              "Conseillère stratégique au sein de plusieurs conseils d'administration, fondatrice et dirigeante de www.mc2consilium.ca, qui fournit des conseils en matière de fusions-acquisitions, en technologie et transformation des affaires. Pionnière des conseils d'administration ‘hors des sentiers battus’, consultante possédant d'excellentes compétences en matière de réflexion stratégique et un talent indéniable pour prendre en compte différentes perspectives, afin de fournir des informations et recommandations exploitables. Leader mondiale multiculturelle avec une expérience dans 6 industries et dans les services de conseil en affaires et TI. Grâce à son expérience en tant que partenaire chez IBM, KPMG et CGI, elle s'est forgé une réputation de leader chevronnée et d’experte respectée dans le domaine de la technologie.",
          },
        },
        {
          member: {
            name: "Stéphanie Levert",
            title: "Consultante exécutive | Consultante en Affaires & Avocate",
            description:
              "Consultante en affaires et avocate reconnue pour son expérience en matière de fusions-acquisitions, de gestion du changement, de planification stratégique, de gouvernance d'entreprise et de protection de la vie privée et de l'innovation. Plus de 20 ans d'expérience dans des fonctions de direction au sein d'un fournisseur mondial de technologies et de logiciels d'imagerie médicale, ainsi qu'une expérience internationale sur 4 continents. Influenceuse de renom et conseillère en matière de risques, elle soutient la croissance et génère la confiance dans la valeur de l'entreprise en menant des actions durables d'atténuation des risques et des investissements légaux, et en fournissant une vision holistique de la préparation des transactions. Polyvalente et flexible, elle parle couramment le français, l'anglais et l'espagnol.",
          },
        },
        {
          member: {
            name: "Harry Bolner",
            title: "Consultant senior TI | Conseiller en Technologie F&A",
            description:
              "Harry est un cadre/ consultant/ directeur et gestionnaire de projet/ entrepreneur TI polyvalent et axé sur les résultats. Depuis plus de 35 ans, il crée et mène à bon terme des projets de plus de 200 millions $US, pour les secteurs de la finance, des soins de santé, de la biotechnologie, des télécommunications, de la manufacture, de l'exploitation minière, de la technologie et du commerce de détail dans le monde entier (Amérique du Nord, Europe, Amérique centrale et Afrique). Harry a occupé des postes de direction dans l'industrie informatique au sein de petites, moyennes et grandes entreprises. Avec une solide expérience multifonctionnelle, Harry apporte des solutions innovantes et créatives dans ses projets pour améliorer l'efficacité et les coûts, respecter les délais et dépasser les objectifs commerciaux. Harry parle couramment 5 langues et détient un titre en électrotechnique.",
          },
        },
        {
          member: {
            name: "Fina Rajerison",
            title: "Analyste et Gestion de Projet | Coordinatrice de Projets F&A",
            description:
              "Organisatrice intrépide ayant le sens du détail et de l'analyse, Fina gère des équipes et des projets. Les clients l’apprécient pour son haut niveau d'éthique professionnelle et son engagement à atteindre les objectifs. Possédant plusieurs années d'expérience dans les services en ligne avec des partenaires internationaux (France, Thaïlande, Russie, Canada et Royaume-Uni), Fina est titulaire d'une maitrise en Finance et Marketing. L’essayer, c'est l'adopter !",
          },
        },
        {
          member: {
            name: "Line Landry",
            title: "Consultante exécutive | Synergies & Optimisation diligence raisonnable F&A",
            description:
              "Line possède plus de 20 ans d'expérience dans les conseils stratégiques et la finance en Amérique du Nord, en Europe et en Afrique. Leader déterminée mais aussi agile, avec un fort penchant pour l'action et l'alignement autour d'une vision, passionnée par l'établissement de relations durables. Elle a occupé des postes de direction et de gestion des petites, moyennes et grandes entreprises.",
          },
        },
        {
          member: {
            name: "Sylvia Morin",
            title: "Consultante exécutive | Communication & Stratégie F&A",
            description:
              "Administratrice de sociétés et consultante possédant une riche expérience dans ou avec des entreprises publiques et privées travaillant dans les secteurs des produits de consommation, des médias, des télécommunications et des technologies. Son parcours professionnel en tant que stratège et communicatrice stratégique lui ont permis d'acquérir une connaissance approfondie des défis liés à la gestion du changement et aux fusions-acquisitions, ainsi que des dynamiques de gestion, de gouvernance et de succession dans les entreprises familiales.",
          },
        },
      ],
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div className="about container">
      <h1>{content.h1}</h1>
      <p>{content.p}</p>
      <div className="team-members">
        {/* {content.teamMembers.member.forEach(({ member }, idx) => (
          <div className="member">
            <div className="profile-picture">{member.name}</div>
            <h5>{member.title}</h5>
            <p>{member.description}</p>
          </div>
        ))} */}
        {/* {content.teamMembers.for(i = 0; i < 3; i++){

`<div className="member">
   <div className="profile-picture">{member.name}</div>
   <h5>{member.title}</h5>
   <p>{member.description}</p>
 </div>`
  
}} */}

        <div className="member">
          <div className="profile-picture">
            <img src={bioPhoto1} />
          </div>
          <span>{content.teamMembers[0].member.name}</span>
          <h5> {content.teamMembers[0].member.title}</h5>
          <p>{content.teamMembers[0].member.description}</p>
        </div>
        <div className="member">
          <div className="profile-picture">
            <img src={bioPhoto6} /> {content.teamMembers[1].member.name}
          </div>
          <h5> {content.teamMembers[1].member.title}</h5>
          <p>{content.teamMembers[1].member.description}</p>
        </div>
        <div className="member">
          <div className="profile-picture">
            <img src={bioPhoto4} /> {content.teamMembers[2].member.name}
          </div>
          <h5> {content.teamMembers[2].member.title}</h5>
          <p>{content.teamMembers[2].member.description}</p>
        </div>
        <div className="member">
          <div className="profile-picture">
            <img src={bioPhoto2} /> {content.teamMembers[3].member.name}
          </div>
          <h5> {content.teamMembers[3].member.title}</h5>
          <p>{content.teamMembers[3].member.description}</p>
        </div>
        <div className="member">
          <div className="profile-picture">
            <img src={bioPhoto3} /> {content.teamMembers[4].member.name}
          </div>
          <h5> {content.teamMembers[4].member.title}</h5>
          <p>{content.teamMembers[4].member.description}</p>
        </div>
        <div className="member">
          <div className="profile-picture">
            <img src={bioPhoto5} /> {content.teamMembers[5].member.name}
          </div>
          <h5> {content.teamMembers[5].member.title}</h5>
          <p>{content.teamMembers[5].member.description}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
