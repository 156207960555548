import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../media/img/whitelogo_mc2.2.png";

const Navbar = (props) => {
  const [navShowing, toggleNav] = useState(false);

  const handleToggleNav = () => {
    toggleNav(!navShowing);
  };
  const closeNav = () => {
    toggleNav(!navShowing);
  };

  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      navItems: ["M&A Services", "Tech & Management Advisory", "Audit 4.0", "Industries", "About", "Contact Us"],
    },
    french: {
      navItems: ["Services Fusion-acquisition", "Conseils - Technologie et Gestion", "Audit 4.0", "Industries", "À propos", "Contactez-nous"],
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  // let menu = document.getElementsByClassName("nav ul li a");
  // let backgroud = document.getElements
  // console.log(menu);

  return (
    <nav>
      <div className={`nav-hamburger ${navShowing ? "open" : ""}`} onClick={handleToggleNav}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <NavLink to="/">
        <img src={Logo} className="nav-logo" />
      </NavLink>
      <ul className={`${navShowing ? "show" : ""}`}>
        {/* {`${navShowing && 'show'}`} */}
        <li>
          <NavLink exact to="/" className="x-bold" activeClassName="underline" onClick={closeNav}>
            {content.navItems[0]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/tech-management-advisory" className="x-bold" activeClassName="underline" onClick={closeNav}>
            {content.navItems[1]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/audit4" className="x-bold" activeClassName="underline" onClick={closeNav}>
            {content.navItems[2]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/industries" className="x-bold" activeClassName="underline" onClick={closeNav}>
            {content.navItems[3]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className="x-bold" activeClassName="underline" onClick={closeNav}>
            {content.navItems[4]}
          </NavLink>
        </li>
        <li className="color bold">
          <NavLink to="/contact" className="x-bold" activeClassName="underline" onClick={closeNav}>
            {content.navItems[5]}
          </NavLink>
        </li>
      </ul>
      <div className="custom-select select-selected">
        <select className="" value={props.language} onChange={(e) => props.handleSetLanguage(e.target.value)}>
          <option value="English" className="option-select">
            {/* {selectedOption} */}En
          </option>
          <option value="French" className="option-select">
            {/* {selectedOption} */}Fr
          </option>
        </select>
      </div>
    </nav>
  );
};

// const changeLanguageLetters = () => {
//   let selectedOption = document.getElementsByClassName(".option-select");
// };

export default Navbar;
