import React from "react";
import cpcs from "../../media/img/clientLogos/cpcs.png";
import cdmv from "../../media/img/clientLogos/cdmv.png";
import nature from "../../media/img/clientLogos/naturestouch.jpg";
import spb from "../../media/img/clientLogos/spb.png";
import csc from "../../media/img/clientLogos/csc.png";
import gse from "../../media/img/clientLogos/gse.jpg";
import oncap from "../../media/img/clientLogos/oncap.png";
import lvm from "../../media/img/clientLogos/lvm.jpg";
import bugatti from "../../media/img/clientLogos/bugatti.png";
import adnm from "../../media/img/clientLogos/adnm.png";
import dteck from "../../media/img/clientLogos/dteck.png";
import crc from "../../media/img/clientLogos/crcsogema.png";
import ship2home from "../../media/img/clientLogos/ship2home.png";
import ortho from "../../media/img/clientLogos/orthocanada.jpg";
// import techno from "../../media/img/clientLogos/image23.jpg";
import energere from "../../media/img/clientLogos/energere.png";
import clearD from "../../media/img/clientLogos/cleardestination.jpg";
import simo from "../../media/img/clientLogos/simo.jpg";
import sogema from "../../media/img/clientLogos/sogema.png";
import ovation from "../../media/img/clientLogos/ovation.png";
import cowater from "../../media/img/clientLogos/cowater.jpg";
import intelerad from "../../media/img/clientLogos/intelerad.png";
import ranger from "../../media/img/clientLogos/ranger.png";
import peak from "../../media/img/clientLogos/peak.png";
import techno from "../../media/img/clientLogos/technowait.jpg";
import delta from "../../media/img/clientLogos/delta.png";
import desjardins from "../../media/img/clientLogos/desjardins.png";
import bonjourSante from "../../media/img/clientLogos/bonjoursante.png";
import tootelo from "../../media/img/clientLogos/tootelo.png";
import kinova from "../../media/img/clientLogos/kinova.png";
import englobe from "../../media/img/clientLogos/englobe.png";
import vsd from "../../media/img/clientLogos/vsd.png";

export const ClientLogosArray = [
  cpcs,
  cdmv,
  nature,
  spb,
  csc,
  gse,
  oncap,
  lvm,
  bugatti,
  adnm,
  dteck,
  crc,
  ship2home,
  ortho,
  techno,
  energere,
  clearD,
  simo,
  sogema,
  ovation,
  cowater,
  intelerad,
  ranger,
  peak,
  delta,
  desjardins,
  bonjourSante,
  tootelo,
  kinova,
  englobe,
  vsd,
];
