import React from "react";
import { Link } from "react-router-dom";

const SolutionTimeline = (props) => {
  return (
    <div className="solution-timeline">
      <h2>{props.content.title}</h2>
      <div className="timeline-diagram">
        <div className="activity 1">{props.content.graphBars[0]}</div>
        <div className="activity 2">{props.content.graphBars[1]}</div>
        <div className="activity 3">{props.content.graphBars[2]}</div>
        <div className="activity 4">{props.content.graphBars[3]}</div>
        <div className="activity 5">{props.content.graphBars[4]}</div>
      </div>
      <Link>{props.content.button}</Link>
    </div>
  );
};

export default SolutionTimeline;
