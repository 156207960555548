import React from "react";

const GeneralDescription = (props) => {
  return (
    <div className="general-description">
      <div>
        <h2>{props.content.h2.a}</h2>
        <span className="description">{props.content.span.a}</span>
        <p>{props.content.p.a}</p>
      </div>
      <div>
        <h2>{props.content.h2.b}</h2>
        <span className="description">{props.content.span.b}</span>
        <p>{props.content.p.b}</p>
      </div>
    </div>
  );
};

export default GeneralDescription;
