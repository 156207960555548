import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../media/img/whitelogo_mc2.2.png";

const Footer = () => {
  let newDate = new Date();
  let year = newDate.getFullYear();

  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      footerTitle: "ABOUT MC2 CONSILIUM",
      footerDescription:
        "We are a boutique advisory services firm dedicated to Mergers and Acquisition and technology challenges. In business since 2013, we’ve helped small and medium sized companies execute mergers involving 20 to 10 000 employees, working in Quebec, across Canada and the world. We help companies leverage technologies for their business in a no-nonsense way.",
      footerCopyright: "All Rights Reserved by",
      navItems: ["M&A Services", "Tech & Management Advisory", "Audit 4.0", "Industries", "About", "Contact Us"],
    },
    french: {
      footerTitle: "À propos de MC2 CONSILIUM",
      footerDescription:
        "Nous sommes une société de services-conseils dédiée aux fusions et acquisitions et aux défis technologiques. En affaires depuis 2013, nous aidons des petites et moyennes entreprises à réaliser des fusions impliquant de 20 à 10 000 employés, œuvrant au Québec, à travers le Canada et dans le monde. Nous aidons les entreprises à tirer parti des technologies pour leur entreprise de manière pragmatique.",
      footerCopyright: "Tous droits réservés",
      navItems: ["Services Fusion-acquisition", "Conseils - Technologie et Gestion", "Audit 4.0", "Industries", "À propos", "Contactez-nous"],
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <footer>
      <div className="grid-2">
        <div>
      <div className="icon-bg">
        <a href="tel:514-219-2332">
          <i className="fas fa-phone-square-alt" />
        </a>
        <a href="mailto:mmcgrath@mc2consilium.ca">
          <i className="fas fa-envelope" />
        </a>
        <a href="https://www.linkedin.com/in/murielmcgrath/">
          <i className="fab fa-linkedin" />
        </a>
      </div>
      <h4>{content.footerTitle}</h4>
      <p>{content.footerDescription}</p>
      </div>
      <div>
      <NavLink to="/">
        <img src={Logo} className="nav-logo" />
      </NavLink>
      <ul>
        <li>
          <NavLink exact to="/" className="x-bold" activeClassName="underline">
            {content.navItems[0]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/tech-management-advisory" className="x-bold" activeClassName="underline">
            {content.navItems[1]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/audit4" className="x-bold" activeClassName="underline">
            {content.navItems[2]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/industries" className="x-bold" activeClassName="underline">
            {content.navItems[3]}
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" className="x-bold" activeClassName="underline">
            {content.navItems[4]}
          </NavLink>
        </li>
        <li className="color bold">
          <NavLink to="/contact" className="x-bold" activeClassName="underline">
            {content.navItems[5]}
          </NavLink>
        </li>
      </ul>
      </div>
      </div>
      <p className="copyright-text">
        Copyright &copy; {year} {content.footerCopyright} <a href="https://sor-seo.com">SOR SEO</a>
      </p>
    </footer>
  );
};

export default Footer;
