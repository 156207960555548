import { useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./styles/styles.scss";

import Footer from "./components/layouts/Footer";

import Home from "./components/pages/Home";

import ScrollToTop from "./components/utils/ScrollToTop";
import Navbar from "./components/layouts/Navbar";
import IndustriesOverview from "./components/pages/IndustriesOverview";
import TechManagementAdvisory from "./components/pages/TechManagementAdvisory";
import IndustryComponent from "./components/pages/IndustryComponent";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Audit4 from "./components/pages/Audit4";

function App() {
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(languageStoredInLocalStorage ? languageStoredInLocalStorage : "English");

  return (
    <div className="App">
      <Router>
        <Navbar
          language={language}
          handleSetLanguage={(language) => {
            setLanguage(language);
            storeLanguageInLocalStorage(language);
          }}
        />
        <ScrollToTop />
        {/* <div className="container"> */}
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/industries" component={IndustriesOverview} />
          <Route exact path="/tech-management-advisory" component={TechManagementAdvisory} />
          <Route exact path="/audit4" component={Audit4} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/about" component={About} />
          <Switch>
            <Route path="/:industryUrl" children={<IndustryComponent />} />
          </Switch>
        </Switch>
        {/* </div> */}

        <Footer />
      </Router>
    </div>
  );
}

function storeLanguageInLocalStorage(language) {
  localStorage.setItem("language", language);
}

export default App;
