import React from "react";
import { Link } from "react-router-dom";

const BridgeBanner = (props) => {
  return (
    <div className="bridge-banner">
      <div className="successful-projects">
        <span className="big-number">+20</span>
        <p>{props.content.title}</p>
      </div>
      <div className="quote-and-person">
        <div className="quote">
          <h2>{props.content.subtitle}</h2>
          <p>{props.content.description}</p>
          <Link to="/about">{props.content.button}</Link>
        </div>
        <div className="person"></div>
        <div />
      </div>
    </div>
  );
};

export default BridgeBanner;
