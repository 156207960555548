import React, {useEffect, useState, useRef} from 'react'
import {Link} from 'react-router-dom'
import Typewriter from 'typewriter-effect'
import Background from './Background'

const CustomModal = () => {

const [show, setShow] = useState(true)
const modal = useRef()
const boxContent = useRef()

const unlockAndRemoveModal = () => {
    setShow(false)
    document.body.style.overflow = 'unset'
    if(modal == true || modal != null){
    modal.current.style.opacity = '0'}

    document.querySelector('.nav-hamburger').style.display = 'block'
}

const removeNav = () => {
    const menu = document.querySelector('.nav-hamburger')
    menu.style.display = 'none'
}



useEffect(()=> {
    // if(show == true){
        setTimeout(()=>showContent(), 5000)
// }
})

// useEffect(()=>addListenerToLinks())
useEffect(()=>{ if(show) { document.body.style.overflow = 'hidden' } else { document.body.style.overflow = 'unset' } }, [show]);
useEffect(()=>boxContent.current.style.display = 'none')
useEffect(()=>removeNav(),[])

const [initiated, setInitiated] = useState(false)





// content.current.style.opacity = '0'



const showContent = () => {
    // setInitiated(true)

    if(show == true || typeof boxContent === 'obj'){
        
    boxContent.current.style.display = 'flex'

    if(boxContent.current.style.display = 'flex'){
        setTimeout(()=>{
            boxContent.current.style.transform = "translateY(100px)"
            boxContent.current.style.opacity = '100%'
            document.querySelector('.Typewriter').style.display = 'none'
        },100)
}
}
}

// const addListenerToLinks = () =>{
//     const navLinks = document.querySelectorAll('nav ul li')

//     navLinks.forEach((link)=>{
//         link.addEventListener("click", unlockAndRemoveModal)
//     })

// if(show ==false){
//     navLinks.forEach((link)=>{
//         link.removeEventListener("click", unlockAndRemoveModal)
//     })
// }

// //     navLinks.map(link=>{
// //         link.addEventListener("click", unlockAndRemoveModal)
// // })
// }



let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
        typewriterText:"How can mc2 help you?",
      category: ["Mergers and Acquisitions","Growth and Performance"],
      title: ["De-risk your most important transactions","Practical business and technology advice to help your business grow and perform"],
      desc: [["DEAL READINESS ", <span className="vertical-bar-style2"> | </span>," EVALUATION ", <span className="vertical-bar-style2"> | </span>, " INTEGRATION MOBILIZATION FOR MERGERS & ACQUISITIONS"],["DEAL READINESS ", <span className="vertical-bar-style2"> | </span>," EVALUATION ", <span className="vertical-bar-style2"> | </span>, " GOVERNANCE"]]
    },
    french: {
        typewriterText:"Comment mc2 peut-il vous aider?",
            category: ["Fusion-Acquisition","Croissance et performance"],
            title: ["Éliminez les risques liés à vos transactions les plus importantes","Obtenez des conseils pratiques en matière d'affaires et de technologie"],
            desc: [["PRÉPARATION À LA TRANSACTION ", <span className="vertical-bar-style2"> | </span>," ÉVALUATION ", <span className="vertical-bar-style2"> | </span>, " MOBILISATION DE L'INTÉGRATION"],["ÉVALUATION ", <span className="vertical-bar-style2"> | </span>," MOBILISATION ", <span className="vertical-bar-style2"> | </span>, " GOUVERNANCE"]]
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);



    return (
        <div className='custom-modal' ref={modal}>
            {/* <Background /> */}
            {/* <h2 onClick={showContent}>Comment mc2 peut vous aider ?</h2> */}
            <Typewriter
                onInit={(typewriter) => {
                typewriter.typeString(`${content.typewriterText}`)
                .pauseFor(3000)
                .deleteAll().start().changeDeleteSpeed(1)
                }}
                
                options={{
                    delay: 80,
                }}
                // options={{
                //     strings: ['Comment mc2 peut-il vous aider?'],
                //     autoStart: true,
                //     loop: false,
                //     delay: '100',
                // }}
            />

            <div className="grid-2" ref={boxContent}>
                <Link to='/' onClick={unlockAndRemoveModal}>
                <div>
                    <p>{content.category[0]}</p>
                    <h3>{content.title[0]}</h3>
                    <p>{content.desc[0]}</p>
                </div>
                </Link>
                <Link to='/tech-management-advisory' onClick={unlockAndRemoveModal} >
                <div>
                <p>{content.category[1]}</p>
                    <h3>{content.title[1]}</h3>
                    <p>{content.desc[1]}</p>
                </div>
                </Link>
            </div>
        </div>
    )
}

export default CustomModal;