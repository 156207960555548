import React from "react";

const SolutionOverview = (props) => {
  // const text = [
  //   {
  //     h4: "Pre-M&A Process & Technology Due Diligence​",
  //     p: "To help you understand process and technology assets & risks and decrease integration risks and costs, speed up integration & reach transaction objectives​",
  //   },
  //   {
  //     h4: "Integration setup and planning​",
  //     p: "To help you to quickly drive vision and approach, integration management office (IMO) setup and governance. High-level integration plan ready for internal teams to implement​",
  //   },
  //   {
  //     h4: "Integration management",
  //     p: "Integration leadership and IMO resourcing throughout your integration, leading internal teams to stay on track",
  //   },
  //   {
  //     h4: "Integration management and functional work stream delivery​",
  //     p: "Comprehensive integration management, including IMO and support for internal resources in some or all functional work streams​",
  //   },
  //   {
  //     h4: "Deal Readiness",
  //     p: "Build sustainable risk mitigation during the pre-deal period, adapt deal processes that will maintain a maximized transaction value. Invest in a few key frameworks and ensure investors will recognize value and reduce transaction fees      ",
  //   },
  // ];
  

  // let text = props.content.solutionOverview.p;

  return (
    <div className="solution-overview">
      <h2>{props.content.h2}</h2>
      <div>
        <div>
          <h4>{props.content.h4.a}​</h4>
          <p>{props.content.p.a}​</p>
        </div>
        <div>
          <h4>{props.content.h4.b}​</h4>
          <p>{props.content.p.b}</p>
        </div>
        <div>
          <h4>{props.content.h4.c}​</h4>
          <p>{props.content.p.c}</p>
        </div>
        <div>
          <h4>{props.content.h4.d}​</h4>
          <p>{props.content.p.d}</p>
        </div>
      </div>
    </div>
  );
};

export default SolutionOverview;

// <div>
//         {text.map((text, i) => (
//           <div className="">
//             <h4>
//               <i class="fas fa-check"></i>&nbsp;

//               {props.content.solutionOverview.h4[i]}
//             </h4>
//             <p>{text.p}</p>
//           </div>
//         ))}
//       </div>

{
  /* <div>
        <h4>Pre-M&A Process & Technology Due Diligence​</h4>
        <p>
          To help you understand process and technology assets & risks and decrease integration risks and costs, speed up integration & reach transaction
          objectives​
        </p>
      </div>
      <div>
        <h4>Integration setup and planning​ ​</h4>
        <p>
          To help you to quickly drive vision and approach, integration management office (IMO) setup and governance. High-level integration plan ready for
          internal teams to implement​
        </p>
      </div>
      <div>
        <h4>Integration management​ ​</h4>
        <p>Integration leadership and IMO resourcing throughout your integration, leading internal teams to stay on track​</p>
      </div>
      <div>
        <h4>Integration management and functional work stream delivery​ ​</h4>
        <p>Comprehensive integration management, including IMO and support for internal resources in some or all functional work streams​</p>
      </div> */
}
