import React from "react";

const Contact = () => {
  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      pageTitle: "Contact Us",
      description:
        "To understand our value, our competitive pricing and the way we work with you about your upcoming transaction or tech  or business investment, please fill in below form.",
      formName: "Your Name",
      formEmail: "Your Email",
      formMessage: "Message",
      formSendButton: "Send",
    },
    french: {
      pageTitle: "Nous contacter",
      description:
        "Pour comprendre notre valeur, nos prix compétitifs et la manière dont nous travaillons avec vous pour votre prochaine transaction ou investissement technologique ou d’affaires, veuillez remplir le formulaire ci-dessous.",
      formName: "Votre nom",
      formEmail: "Votre e-mail",
      formMessage: "Message",
      formSendButton: "Envoyer",
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div className="contact container">
      <div>
        <h1>{content.pageTitle}</h1>
        <p>
          <i class="fas fa-mobile"></i>&nbsp;450 736 0315
        </p>
        <p>
          <i class="fas fa-envelope"></i> &nbsp;mmcgrath@mc2consilium.ca
        </p>
      </div>

      <div className="form-container">
        <p className=" medium-small">{content.description}</p>
        <form name="contact" method="post">
          <div className="form-group">
            <input type="hidden" name="form-name" value="contact" />
          </div>
          <p>
            <label>
              {content.formName}: <input type="text" name="name" />
            </label>
          </p>
          <p>
            <label>
              {content.formEmail}: <input type="email" name="email" />
            </label>
          </p>
          <p>
            <label>
              {content.formMessage}: <textarea name="message"></textarea>
            </label>
          </p>
          <p className="py-2">
            <button type="submit" className="btn btn-primary btn-block">
              {content.formSendButton}
            </button>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Contact;
