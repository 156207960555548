import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import visual1 from "../../img/na-night.png";
// import Charts4 from "../Charts4";
import murielPhoto from "../../media/img/murielPhoto.png";
// import icon1 from "../../img/mc2Icons/due-dil.png";
// import icon2 from "../../img/mc2Icons/integration.png";
// import icon3 from "../../img/mc2Icons/integration-management.png";
// import icon4 from "../../img/mc2Icons/workstream-delivery.png";
// import TechAdvisoryHeroImage from "../../img/5.png";
// import OurServices2 from "../OurServices2";

import leadershipLogo1 from "../../media/img/clientLogos/desjardins.png";
import leadershipLogo2 from "../../media/img/clientLogos/cgi.png";
import leadershipLogo3 from "../../media/img/clientLogos/kpmg.png";
import leadershipLogo4 from "../../media/img/clientLogos/humania.png";
import leadershipLogo5 from "../../media/img/clientLogos/cpcs.png";
import leadershipLogo6 from "../../media/img/clientLogos/ibm.png";
import leadershipLogo7 from "../../media/img/clientLogos/wcd.jpeg";
import leadershipLogo8 from "../../media/img/clientLogos/institute.png";
import leadershipLogo9 from "../../media/img/clientLogos/ministere.png";

const Audit4 = () => {
  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      header: {
        title: "Digital transformation for SMEs",
        description: "DIGITAL READINESS EVALUATION | DIGITAL PLAN | ACTION PLAN",
        button: "Digital Transformation & Audit 4.0",
      },
      generalDescription: {
        title: "WHY WORK WITH MC2 CONSILIUM?",
        list: {
          listItem1: "Our certification gives you very significant subsidies.",
          listItem2: "Our full independence ensures that our recommendations are 100% aligned with your objectives, not ours.",
          listItem3: "Practical, innovative advice based on your reality as a SME",
        },
      },
      promoBlockServices: {
        title: "250+ successful projects in business optimization and technology-enabled business transformation",
      },
      card:{
        title: "Our project leadership",
        img: ["../public/images/clientLogos/campeau.jpg", "../public/images/clientLogos/campeau.jpg"],
        listItems: [
          ["Member of the Board", "Chairman of the Venture Capital Investment Committee", "Member of the Governance and HR Committee"], ["Member of the Board", "Member of the HR Committee"], "Member of the Board" , "Industry 4.0 Audit Certification", "ICD.D Certification", "Member of the WCD, Quebec Chapter", "Associate Career in Strategy and Transformation"]
      }
    },
    french: {
      header: {
        title: "Des conseils pratiques en matière d'affaires et de technologie pour aider votre entreprise à croitre et à être performante",
        description: "ÉVALUATION | MOBILISATION | GOUVERNANCE",
        button: "Digital Transformation & Audit 4.0",
      },
      generalDescription: {
        title: "Services-conseils en Technologie et en Gestion",
        description: "POUR LA CROISSANCE ET LA PERFORMANCE",
        list: {
          listItem1: "Bâtissez une entreprise et une gouvernance optimales",
          listItem2: "Dépensez votre budget légal de manière judicieuse",
          listItem3: "Optimisez votre fonction de réglementation et de conformité",
          listItem4: "Simplifiez et optimisez votre activité grâce aux technologies",
          listItem5: "Ayez l’assurance que vos systèmes d’information, vos TI et vos technologies apportent une valeur ajoutée à votre entreprise",
        },
        button: "Our recent Tech and Advisory projects",
      },
      promoBlockServices: {
        title: "250+ projets à succès en valorisation d’entreprise et en transformation des affaires par les technologies",
      },
      card: {
        title: "Notre leadership de projet",
        img: ["../public/images/clientLogos/campeau.jpg", "../public/images/clientLogos/campeau.jpg"],
        listItems: [
          ["Membre du Conseil", "Présidente du comité d'investissement en Capital de Risque", "Membre du comité gouvernance et RH"],["Membre du Conseil","Membre du comité RH"],"Membre du Conseil","Certification Audit Industrie 4.0","Certification IAS.A","Membre du WCD, chapitre du Québec","Carrière d'associée en Stratégie et Transformation"]
        ,
      },
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div className="audit4">
      <header>
        <h1>{content.generalDescription.title}</h1>
        <p>{content.generalDescription.description}</p>
      </header>

      <div>
        <ul className="check-list">
          <li>
            <i class="fa fa-check-circle" aria-hidden="true" />
            {content.generalDescription.list.listItem1}
          </li>
          <li>
            <i class="fa fa-check-circle" aria-hidden="true" />
            {content.generalDescription.list.listItem2}
          </li>
          <li>
            <i class="fa fa-check-circle" aria-hidden="true" />
            {content.generalDescription.list.listItem3}
          </li>
        </ul>
      </div>

      <div className="main-slogan">
        <span>{content.promoBlockServices.title}</span>
      </div>

      {/* // OUR TEAM -> MM SL HB FR */}

      {/* // NOTRE LEADERSHIP DE PROJET */}

      <div className="project-leadership-section">
        <h2>{content.card.title}</h2>

        {/* <div className="card">
          <img src={content.card.img[0]} className="leadership-logo" alt="" />
          <ul className="">
            <li>{content.card.listItems[0][0]}</li>
            <li>{content.card.listItems[0][1]}</li>
            <li>{content.card.listItems[0][2]}</li>
          </ul>
        </div> */}

        <div className="leadership-card">
          <img src={leadershipLogo1} className="leadership-logo" alt="" />
          <ul className="">
            <li>{content.card.listItems[0][0]}</li>
            <li>{content.card.listItems[0][1]}</li>
            <li>{content.card.listItems[0][2]}</li>
          </ul>
        </div>

        <div className="leadership-card">
          <img src={leadershipLogo4} className="leadership-logo" alt="" />
          <div className="">
            <ul className="">
              <li>{content.card.listItems[1][0]}</li>
              <li>{content.card.listItems[1][1]}</li>
            </ul>
          </div>
        </div>

        <div className="leadership-card">
          <img src={leadershipLogo5} className="leadership-logo" alt="" />
          <div className="">
            <ul className="">
              <li>{content.card.listItems[2]}</li>
            </ul>
          </div>
        </div>

        <div className="leadership-card">
          <img src={leadershipLogo9} className="leadership-logo" alt="" />
          <div className="">
            <ul className="">
              <li>{content.card.listItems[3]}</li>
            </ul>
          </div>
        </div>
        <div className="leadership-card">
          <img src={leadershipLogo8} width="10px" className="" alt="" />
          <div className="">
            <ul className="">
              <li>{content.card.listItems[4]}</li>
            </ul>
          </div>
        </div>
        <div className="leadership-card">
          <img src={leadershipLogo7} className="leadership-logo1" alt="" />
          <div className="">
            <ul className="">
              <li>{content.card.listItems[5]}</li>
            </ul>
          </div>
        </div>
        <div className="leadership-card">
          <div className="grid-3">
            <img src={leadershipLogo3} className="leadership-logo2" alt="" />
            <img src={leadershipLogo2} className="leadership-logo2" alt="" />
            <img src={leadershipLogo6} className="leadership-logo2" alt="" />
          </div>
          <div className="">
            <ul className="">
              <li>{content.card.listItems[6]}</li>
            </ul>
          </div>
        </div>
      </div>

      {/*       
      <div className="bg-primary">
        <div className="container py-3">
          <h1>NOTRE EXPERTISE</h1>
          <ul className="grid-2">
            <li>
              <span className="medium my-2">
                <br /> <span className="big-numbers">50+</span>
                <br />
                projets en <span className="color">transformation numérique</span> dans 6 industries
              </span>
            </li>
            <li>
              <span className="medium my-2">
                <br />
                <span className="big-numbers">40+</span>
                <br />
                projets de <span className="color">sélection et d’implantation</span> de nouvelles technologies
              </span>
            </li>
          </ul>
          <div className="container my-2">
            
          </div>
        </div>
      </div>
      <div className="container py-2">
        <h1>NOTRE ÉQUIPE</h1>
      </div>
      <div className="bg-light all-center">
        <OurTeamOverview2 />
      </div>

       */}
    </div>
  );
};

export default Audit4;
