import React from "react";
import { Link } from "react-router-dom";
import { ClientLogosArray } from "../utils/ClientLogosExport";

const SocialProof = (props) => {
  return (
    <div className="social-proof">
      <h2>{props.content.title}</h2>
      <div>
        {ClientLogosArray.map((image) => (
          <div>
            <img src={image} />
          </div>
        ))}
      </div>
      <Link to="/industries">{props.content.button}</Link>
    </div>
  );
};

export default SocialProof;
