import React, { Fragment } from "react";
import { Link } from "react-router-dom";
// import visual1 from "../../img/na-night.png";
// import Charts4 from "../Charts4";
import murielPhoto from "../../media/img/murielPhoto.png";
// import icon1 from "../../img/mc2Icons/due-dil.png";
// import icon2 from "../../img/mc2Icons/integration.png";
// import icon3 from "../../img/mc2Icons/integration-management.png";
// import icon4 from "../../img/mc2Icons/workstream-delivery.png";
// import TechAdvisoryHeroImage from "../../img/5.png";
// import OurServices2 from "../OurServices2";

const TechManagementAdvisory = () => {
  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      header: {
        title: "Practical business and technology advice to help your business grow and perform",
        description: "DEAL READINESS | EVALUATION | INTEGRATION MOBILIZATION FOR MERGERS & ACQUISITIONS",
        button: "Digital Transformation & Audit 4.0",
      },
      generalDescription: {
        title: "Tech and Management Advisory Services",
        description: "FOR GROWTH AND PERFORMANCE",
        list: {
          listItem1: "Build optimal business and governance",
          listItem2: "Spend your legal budget where it matters",
          listItem3: "Optimize your regulatory and compliance function",
          listItem4: "Simplify and optimize your business with technologies",
          listItem5: "Ensure your information systems and IT and technologies brings more value to your business.",
        },
        button: "Our recent advisory projects - technology and management",
      },
      promoBlockServices: {
        title: "Tech and Management Advisory Services",
        h4: [
          "Management and governance consulting",
          "Tech advisory services",
          "Digital transformation and Audit 4.0",
          "Legal services optimization",
          "Compliance and regulatory consulting",
        ],
        span: [
          "Build an optimal business and governance",
          "A simple, cost efficient approach & method to optimize information systems and IT and ensure technology brings more value to the business.",
          "Simplify and optimize your business with technologies",
          "Spend your Legal Budget where it matters",
          "Optimize your regulatory and compliance function",
        ],
        p: [
          "Put in a new governance model for empowering leadership Increase leader accountability and motivation with practical advice and simple tools. Diagnose key processes (sales, operations, HR, procurement, finance, technology) to reduce costs and risks. Gain business agility.",
          "Evaluate and optimize ERP, CRM and SCM (and other solutions). RFP management. Improve IT costs and processes. Contain IT costs and risks. IT procurement & Outsourcing evaluation. IT project remediation Project quality assurance",
          " Transform services or businesses, through replacing non-digital or manual processes with digital processes or replacing older digital technology with newer digital technology. Digital solutions enable – in addition to efficiency via automation – new types of innovation and creativity, rather than simply enhancing and supporting traditional methods. Examples include software connectivity of equipment, workstations, standardization of processes that facilitate data sharing, control over work methods and processes, etc.",
          "We help you determine where and how to invest your legal spendings, supporting your goals and strategy. We help you optimize and enhance your legal services with targeted strategies and better processes.",
          "Can compliance bring value? ISO 13485 & 27001, medical devices and equipment, certifications, etc. are complicated. Many regulations and laws to comply to. Compliance should be supporting efficient development and delivery operations and enhancing security. You need just enough. We help evaluate your processes and procedures and reduce risks, improve and simplify quality systems. We help evaluate, choose or optimize your eQMS",
        ],
        mainPromoText: "Benefit from our certified auditors and obtain 50% subsidies.",
      },
      successfulProjects: {
        title: "Successful Tech and Management advisory projects",
        p: " As the leader of the firm, Muriel builds on her journey as a partner and executive at KPMG, IBM and CGI to advise and support companies in strategy and business transformation through technology. Over the years, she has carved out a reputation as a seasoned leader and influential leader in innovation, digital transformation and governance. Muriel and her team bring rich business experience and ​​expertise, delivered practically.",
        button: "Meet Our Team",
        letters: "ICD.D",
        jobTitle: "President & Corporate Director",
      },
      sectionWhenDoYouNeedUs: {
        title: "When do you need us for Tech and Management Advisory",
        listItems: [
          "Fast track a strategic planning or strategic management plan",
          "Reach stakeholder expectations in terms of growth and performance, ESG and governance",
          "Improve leadership accountability",
          "Align leaders to strategic and operational goals",
          "Build technology transformation roadmap (and get subsidies)",
          "Reduce tech risks and costs",
        ],
      },
      clientsWeVeHelped: {
        title: "Clients we’ve helped: When to work with MC2 for IT advisory",
        p: "IS/ IT practical expertise | Limited internal team expertise | Independent assessment and business value needed | Credibility to bring back confidence",
        button: "Talk to an advisor",
      },
    },
    french: {
      header: {
        title: "Des conseils pratiques en matière d'affaires et de technologie pour aider votre entreprise à croitre et à être performante",
        description: ["ÉVALUATION ",<span className="vertical-bar-style">|</span>," MOBILISATION ",<span className="vertical-bar-style">|</span>," GOUVERNANCE"],
        button: "Digital Transformation & Audit 4.0",
      },
      generalDescription: {
        title: "Services-conseils en Technologie et en Gestion",
        description: "POUR LA CROISSANCE ET LA PERFORMANCE",
        list: {
          listItem1: "Bâtissez une entreprise et une gouvernance optimales",
          listItem2: "Dépensez votre budget légal de manière judicieuse",
          listItem3: "Optimisez votre fonction de réglementation et de conformité",
          listItem4: "Simplifiez et optimisez votre activité grâce aux technologies",
          listItem5: "Ayez l’assurance que vos systèmes d’information, vos TI et vos technologies apportent une valeur ajoutée à votre entreprise",
        },
        button: "Nos récents projets en conseil - technologie et gestion",
      },
      promoBlockServices: {
        title: "Services-conseils en Technologie et Gestion",
        h4: [
          "Conseils en gestion et gouvernance",
          "Services-conseils en Technologie",
          "Transformation numérique et audit 4.0",
          "Optimisation des services juridiques",
          "Conseils en matière de conformité et de réglementation",
        ],
        span: [
          "Construisez une entreprise et une gouvernance optimales.",
          "Une approche et une méthode simple et rentable pour optimiser les systèmes d’information et les TI et faire en sorte que la technologie apporte plus de valeur à l’entreprise.",
          "Simplifiez et optimisez votre entreprise grâce aux technologies.",
          "Dépensez votre budget légal de manière judicieuse.",
          "Optimisez votre fonction de réglementation et de conformité.",
        ],
        p: [
          "Mise en place d’un nouveau modèle de gouvernance pour responsabiliser les dirigeants. Accroissement de la responsabilité et de la motivation des dirigeants grâce à des conseils pratiques et des outils simples. Diagnostic des processus clés (ventes, marketing, opérations, RH, légal, conformité, achats, finances, technologie) pour réduire les coûts et les risques. Gain en agilité commerciale.",
          "Évaluation et optimisation des solutions ERP, CRM, SCM (entre autres). Gestion des appels d’offres. Amélioration des coûts et processus informatiques. Maîtrise des coûts et risques informatiques. Évaluation des achats et de l’externalisation informatique. Réhabilitation de projets informatiques. Assurance qualité des projets.",
          "Transformation des services ou des entreprises, en remplaçant les processus manuels par des processus numériques, ou en remplaçant les anciennes technologies numériques par des plus récentes. Les solutions numériques permettent, outre l’efficacité par l’automatisation, de nouveaux types d’innovation et de créativité, sans se contenter d’améliorer et soutenir les méthodes traditionnelles. Il s’agit par exemple de la connectivité logicielle des équipements et postes de travail, de la normalisation des processus qui facilite le partage des données, du contrôle des méthodes et des processus de travail, etc.",
          "Nous vous aidons à déterminer où et comment investir vos dépenses légales, en soutenant vos objectifs et votre stratégie. Nous vous aidons à optimiser et à améliorer vos services juridiques grâce à des stratégies ciblées et de meilleurs processus.",
          "La conformité peut-elle apporter de la valeur ? Les normes ISO 13485 et 27001, les dispositifs et équipements médicaux, les certifications, etc. sont compliqués. Il faut se conformer à de nombreuses lois et réglementations. La conformité doit favoriser le développement efficace, les opérations de livraison et renforcer la sécurité. Il en faut juste assez. Nous vous aidons à évaluer vos processus et procédures et à réduire les risques, à améliorer et à simplifier les systèmes de qualité. Nous vous aidons à évaluer, choisir ou optimiser votre SMQ.",
        ],
        mainPromoText: "Bénéficiez de nos auditeurs certifiés et obtenez 50% de subventions.",
      },
      successfulProjects: {
        title: "Projets de conseil en technologie et en gestion réussis",
        p: "En tant que dirigeante du cabinet, Muriel s’appuie sur son parcours d’associée et de cadre chez KPMG, IBM et CGI pour conseiller et accompagner les entreprises dans leur stratégie et leur transformation par les technologies. Au fil des années, elle s’est forgé une réputation de leader chevronnée et influente en matière d’innovation, de transformation numérique et de gouvernance. Muriel et son équipe apportent une riche expérience et expertise d’affaires, livrée de manière pratique.",
        button: "Rencontrez notre équipe",
        letters: "IAS.A",
        jobTitle: "Présidente & Administratrice de société",
      },
      sectionWhenDoYouNeedUs: {
        title: "Pourquoi recourir à nos Conseils en Technologie et Gestion",
        listItems: [
          "Accélérer une planification stratégique ou un plan de gestion stratégique",
          "Répondre aux attentes des parties prenantes en matière de croissance et de performance, d’ESG et de gouvernance",
          "Améliorer l’imputabilité des gestionnaires",
          "Aligner les gestionnaires aux objectifs stratégiques et opérationnels",
          "Établir une feuille de route pour la transformation technologique (et obtenir des subventions)",
          "Réduire les risques et les coûts technologiques",
        ],
      },
      clientsWeVeHelped: {
        title: "Clients que nous avons aidés",
        p: ["EXPERTISE PRATIQUE EN SI/TI ",<span className="vertical-bar-style">|</span>," EXPERTISE LIMITÉE DE L’ÉQUIPE INTERNE ",<span className="vertical-bar-style">|</span>, " ÉVALUATION INDÉPENDANTE ET VALEUR ÉCONOMIQUE REQUISE ",<span className="vertical-bar-style">|</span>," CRÉDIBILITÉ POUR RAMENER LA CONFIANCE"],
        button: "Parler à un conseiller",
      },
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <>
      <div className="tech-management-advisory">
        <header>
          <h1>{content.header.title}</h1>
          <p>{content.header.description}</p>

          {/* <Link to="/audit-industrie-4.0" className="btn btn-primary btn-block my-2"></Link> */}
        </header>

        <div className="tech-mgmt-advisory">
          <h2>{content.generalDescription.title}</h2>
          <span>{content.generalDescription.description}</span>
          <div className="section">
            <blockquote>
              <ul>
                <li>
                  <i class="fas fa-check-square"></i>
                  {content.generalDescription.list.listItem1}
                </li>
                <li>
                  <i class="fas fa-check-square"></i>
                  {content.generalDescription.list.listItem2}
                </li>
                <li>
                  <i class="fas fa-check-square"></i>
                  {content.generalDescription.list.listItem3}
                </li>
                <li>
                  <i class="fas fa-check-square"></i>
                  {content.generalDescription.list.listItem4}
                </li>
                <li>
                  <i class="fas fa-check-square"></i>
                  {content.generalDescription.list.listItem5}
                </li>
              </ul>
            </blockquote>

            <Link to="/industries" className="btn draw-border">
              {content.generalDescription.button}
            </Link>
          </div>
        </div>

        <div className="promo-block-services">
          <h2>{content.promoBlockServices.title}</h2>
          <div>
            <h4>{content.promoBlockServices.h4[0]}</h4>
            <span>{content.promoBlockServices.span[0]}</span>
            <p>{content.promoBlockServices.p[0]}</p>
          </div>
          <div>
            <h4>{content.promoBlockServices.h4[1]}</h4>
            <span>{content.promoBlockServices.span[1]}</span>
            <p>{content.promoBlockServices.p[1]}</p>
          </div>
          <div>
            <h4>{content.promoBlockServices.h4[2]}</h4>
            <span>{content.promoBlockServices.span[2]}</span>
            <p>{content.promoBlockServices.p[2]}</p>
          </div>
          <div className="main-promo-text">{content.promoBlockServices.mainPromoText}
          <Link className='btn draw-border'>En savoir plus</Link>
          </div>
          <div>
            <h4>{content.promoBlockServices.h4[3]}</h4>
            <span>{content.promoBlockServices.span[3]}</span>
            <p>{content.promoBlockServices.p[3]}</p>
          </div>
          <div>
            <h4>{content.promoBlockServices.h4[4]}</h4>
            <span>{content.promoBlockServices.span[4]}</span>
            <p>{content.promoBlockServices.p[4]}</p>
          </div>
        </div>

        <div className="section succesful-projects">
          <div>
            <h3>{content.successfulProjects.title}</h3>
            <p>{content.successfulProjects.p}</p>
            <Link to="/about" className="btn draw-border">
              {content.successfulProjects.button}
            </Link>
          </div>
          <div className="next-to-person-text">
            <h3 className="text-left">
              Muriel McGrath {content.successfulProjects.letters}&nbsp;<i className="fab fa-linkedin"></i>
            </h3>
            <span className="text-left">{content.successfulProjects.jobTitle}</span>
            <img src={murielPhoto} alt="" width="400px" />
          </div>
        </div>
        <div className="section bg-dark">
          <h2>{content.sectionWhenDoYouNeedUs.title}</h2>

          <ul>
            <li>
              <i class="fas fa-check-square"></i>
              &nbsp;{content.sectionWhenDoYouNeedUs.listItems[0]}
            </li>
            <li>
              <i class="fas fa-check-square"></i>
              &nbsp;{content.sectionWhenDoYouNeedUs.listItems[1]}
            </li>
            <li>
              <i class="fas fa-check-square"></i>
              &nbsp;{content.sectionWhenDoYouNeedUs.listItems[2]}
            </li>
          </ul>

          <ul>
            <li>
              <i class="fas fa-check-square"></i>
              &nbsp;{content.sectionWhenDoYouNeedUs.listItems[3]}
            </li>
            <li>
              <i class="fas fa-check-square"></i>
              &nbsp;{content.sectionWhenDoYouNeedUs.listItems[4]}
            </li>
            <li>
              <i class="fas fa-check-square"></i>
              &nbsp;{content.sectionWhenDoYouNeedUs.listItems[5]}
            </li>
          </ul>
        </div>

        <div className="section clients-we-ve-help">
          <h3>{content.clientsWeVeHelped.title}</h3>
          <p>{content.clientsWeVeHelped.p}</p>
          <Link to="/contact" className="btn draw-border">
            {content.clientsWeVeHelped.button}
          </Link>
        </div>
      </div>
    </>
  );
};

export default TechManagementAdvisory;
