import React from "react";
import { Link } from "react-router-dom";
import industry1Image from "../../media/img/industries/Tech.jpg";
import industry2Image from "../../media/img/industries/Clean.jpg";
import industry3Image from "../../media/img/industries/Industry4.0.jpg";
import industry4Image from "../../media/img/industries/Pro.jpg";
import industry5Image from "../../media/img/industries/PE.jpg";
import industry6Image from "../../media/img/industries/Logistics.jpg";

const IndustriesOverview = () => {
  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    english: {
      overview: {
        title: "Our experience in your industry",
        industry1: "TECHNOLOGY",
        industry2: "SERVICES",
        industry3: "CLEAN TECH & ENGINEERING",
        industry4: "MANUFACTURING & INDUSTRY 4.0",
        industry5: "FINANCING",
        industry6: "DISTRIBUTION & LOGISTICS",
      },
      techManagementAvdisoryServices: {
        title: "Tech and Management Advisory Services",
        description: "DEAL READINESS | EVALUATION | INTEGRATION MOBILIZATION FOR MERGERS & ACQUISITIONS",
      },
    },
    french: {
      overview: {
        title: "Notre expérience dans votre secteur",
        industry1: "TECHNOLOGIE",
        industry2: "SERVICES",
        industry3: "TECHNOLOGIES PROPRES & INGÉNIERIE",
        industry4: "MANUFACTURIER & INDUSTRIE 4.0",
        industry5: "FINANCEMENT",
        industry6: "DISTRIBUTION & LOGISTIQUE",
      },
      section2: {
        title: "Logiciels, solutions et services technologiques",
        backButton: "Retour à nos secteurs d'industries",
        clientChallengesTitle: "Défis des clients",
        clientChallenges: "Besoin de surpasser les géants du secteur dans les appels d'offres critiques",
        // , "Besoin d'optimiser le département (personnes, processus et outils)", "Optimisation des ventes et du marketing", "Besoin de faire passer l'entreprise au niveau supérieur",
      },
    },
  };

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div className="industries-overview">
      <header>
        <h1>{content.overview.title}</h1>
      </header>
      <ul>
        <Link to="/technology-software">
          <img src={industry1Image} />
          <h3>{content.overview.industry1}</h3>
        </Link>
        <Link to="/professional-services">
          <img src={industry4Image} />
          <h3>{content.overview.industry2}</h3>
        </Link>
        <Link to="/clean-tech-and-engineering">
          <img src={industry2Image} />
          <h3>{content.overview.industry3}</h3>
        </Link>
        <Link to="/manufacturing-4.0">
          <img src={industry3Image} />
          <h3>{content.overview.industry4}</h3>
        </Link>
        <Link to="/private-vc-and-financial-services">
          <img src={industry5Image} />
          <h3>{content.overview.industry5}</h3>
        </Link>
        <Link to="/distribution-and-logistics">
          <img src={industry6Image} />
          <h3>{content.overview.industry6}</h3>
        </Link>
      </ul>
    </div>
  );
};

export default IndustriesOverview;
