import React from "react";
import { Link, useParams } from "react-router-dom";

const IndustryComponent = () => {
  // if component prop = "technology", serve component for technology industry

  let languageStoredInLocalStorage = localStorage.getItem("language");

  let { industryUrl } = useParams();

  let content = {
    english: {
      industry: {
        technology: {
          h1: "Technology software, solutions and services",
          backButton: "Back to our industries",
          clientChallenges: {
            title: "Client Challenges",
            li: {
              a: "Need to beat industry giants in critical RFP",
              b: "Need to optimize department (people, processes and tools)",
              c: "Sales and Marketing optimization",
              d: "Need to bring Co. to the next level",
            },
          },
          ourSolutions: {
            title: "Our Solutions",
            li: {
              a: "Tech solution value positioning",
              b: "Target Operational Model & Transformation roadmap",
              c: "Growth Strategy and interim sales management",
              d: "Strategy, governance and process optimization advisory",
            },
          },
          outcome: {
            title: "Outcome (or result)",
            listHeading: {
              a: "Talent",
              b: "Reduce Risks",
              c: "Augment Speed",
            },
            listItem: {
              a: "Increased team productivity & motivation",
              b: "Risk mitigation and action plan",
              c: "Significant reduction of integration time",
            },
          },
          clientLogos: ["sogema.png", "cleardestination.jpg", "bonjoursante.png", "dteck.png", "vsd.png", "tootelo.png", "adnm.png", "technowait.jpg"],
        },
        services: {
          h1: "Professional services",
          backButton: "Back to our industries",
          clientChallenges: {
            title: "Client Challenges",
            li: {
              a: "Undertand acquisition value",
              b: "Harmonize HR conditions post merger",
              c: "Independant assessment of acquisition",
              d: "Quick and low cost integration",
            },
          },
          ourSolutions: {
            title: "Our Solutions",
            li: {
              a: "Tech solution value positioning",
              b: "HR best in class advisory",
              c: "Business and IT due diligence",
              d: "Integration management office and Technology solutioning",
            },
          },
          outcome: {
            title: "Outcome (or result)",
            listHeading: {
              a: "Accrued confidence",
              b: "Solid Solution",
              c: "Increase speed",
            },
            listItem: {
              a: "Investor comfort with deal & audited assets",
              b: "Efficient IT solutions & quick integration",
              c: "Significant reduction of integration time",
            },
          },
          clientLogos: ["cowater.jpg", "sogep.jpg", "crcsogema.png", "spb.png"],
        },
        cleanTechAndEngineering: {
          h1: "Clean Tech & Engineering",
          backButton: "Back to our industries",
          clientChallenges: {
            title: "Client Challenges",
            li: {
              a: "Need to execute multiple complex carve outs",
              b: "No idea on costs and fit of top tier ERP included in deal",
              c: "Need local experts with right language and extensive networks",
              d: "No experts to de-risk complex and large integration",
            },
          },
          ourSolutions: {
            title: "Our Solutions",
            li: {
              a: "Carve-Out integration plan",
              b: "ERP Total Cost of Ownership",
              c: "Solution selection process and management",
              d: "Integration Management Office",
            },
          },
          outcome: {
            title: "Outcome (or result)",
            listHeading: {
              a: "Tech Network",
              b: "Reduced costs",
              c: "Increase speed",
            },
            listItem: {
              a: "Managed quality and cost of 3rd party integration suppliers",
              b: "Very high reduction of integration costs",
              c: "Significant reduction of integration time",
            },
          },
          clientLogos: ["englobe.png", "lvm.jpg", "simo.jpg", "energere.png"],
        },
        manufacturing: {
          h1: "Manufacturing & Industry 4.0",
          backButton: "Back to our industries",
          clientChallenges: {
            title: "Client Challenges",
            li: {
              a: "Need value and opportunities of IT assets to negotiate deal",
              b: "David buying Goliath - Need top experts",
              c: "Growth and governance dryspell",
              d: "No experts to de-risk complex and large integration",
            },
          },
          ourSolutions: {
            title: "Our Solutions",
            li: {
              a: "IT pre-M&A Audit",
              b: "Integration Management Office - IS/IT workstream",
              c: "Governance and Go To Market advisory",
              d: "IT integration management",
            },
          },
          outcome: {
            title: "Outcome (or result)",
            listHeading: {
              a: "Reduced cost",
              b: "Trust",
              c: "Increase speed",
            },
            listItem: {
              a: "Reduction of deal price",
              b: "Reassured and informed investors",
              c: "Significant reduction of integration time",
            },
          },
          clientLogos: ["synergex.jpg", "solmax.png", "gse.jpg", "kinova.png", "ranger.png"],
        },
        financing: {
          h1: "Financing",
          backButton: "Back to our industries",
          clientChallenges: {
            title: "Client Challenges",
            li: {
              a: "Need for quick knowledge of costs and risks to implement business IT solution",
              b: "Quantify post-investment synergies and challenges",
              c: "Know costs and risks of global integration",
              d: "No experience in complex and large IT and process integration IT",
            },
          },
          ourSolutions: {
            title: "Our Solutions",
            li: {
              a: "Regulatory Audit Solution selection",
              b: "Process and Governance Audit",
              c: "M&A Integration due diligence",
              d: "Integration Management Office",
            },
          },
          outcome: {
            title: "Outcome (or result)",
            listHeading: {
              a: "Accrued confidence",
              b: "Significant savings",
              c: "Choices",
            },
            listItem: {
              a: "Reassured investors",
              b: "Reduced integration costs",
              c: "Reduced cost of compliance",
            },
          },
          clientLogos: ["desjardins.png", "peak.png"],
        },
        distributionAndLogistics: {
          h1: "Distribution & Logistics",
          backButton: "Back to our industries",
          clientChallenges: {
            title: "Client Challenges",
            li: {
              a: "Need for quick knowledge of costs and risks to implement business IT solution",
              b: "Quantify post-investment synergies and challenges",
              c: "Know costs and risks of global integration",
              d: "No experience in complex and large IT and process integration IT",
            },
          },
          ourSolutions: {
            title: "Our Solutions",
            li: {
              a: "Regulatory Audit Solution selection",
              b: "Process and Governance Audit",
              c: "M&A Integration due diligence",
              d: "Integration Management Office",
            },
          },
          outcome: {
            title: "Outcome (or result)",
            listHeading: {
              a: "Accrued confidence",
              b: "Significant savings",
              c: "Choices",
            },
            listItem: {
              a: "Reassured investors",
              b: "Reduced integration costs",
              c: "Reduced cost of compliance",
            },
          },
          clientLogos: ["bugatti.png", "orthocanada.jpg", "naturestouch.jpg", "cdmv.png", "csc.png", "cpcs.png", "ovation.png", "delta.png", "ship2home.png"],
        },
      },
    },

    // ____________________________________________________________________________________________________

    // F R E N C H ____________________________________________________________________________________________________

    // ____________________________________________________________________________________________________

    french: {
      industry: {
        technology: {
          h1: "Logiciels, solutions et services technologiques",
          backButton: "Retour à nos secteurs d'industries",
          clientChallenges: {
            title: "Défis des clients",
            li: {
              a: "Besoin de surpasser les géants du secteur dans les appels d'offres critiques",
              b: "Besoin d'optimiser le département (personnes, processus et outils)",
              c: "Optimisation des ventes et du marketing",
              d: "Besoin de faire passer l'entreprise au niveau supérieur",
            },
          },
          ourSolutions: {
            title: "Solutions MC2",
            li: {
              a: "Positionnement de la valeur des solutions technologiques",
              b: "Modèle opérationnel cible et feuille de route de transformation",
              c: "Stratégie de croissance et gestion des ventes par intérim",
              d: "Conseils en stratégie, gouvernance et optimisation des processus",
            },
          },
          outcome: {
            title: "Résultats",
            listHeading: {
              a: "Talent",
              b: "Réduction des risques",
              c: "Vitesse accélérée",
            },
            listItem: {
              a: "Augmentation de la productivité et de la motivation de l'équipe",
              b: "Atténuation des risques et plan d'action",
              c: "Réduction significative du temps d'intégration",
            },
          },
          clientLogosTitle: "Quelques clients que nous avons aidés :",
          clientLogos: ["sogema.png", "cleardestination.jpg", "bonjoursante.png", "dteck.png", "vsd.png", "tootelo.png", "adnm.png", "technowait.jpg"],
        },
        services: {
          h1: "Services",
          backButton: "Retour à nos secteurs d'industries",
          clientChallenges: {
            title: "Défis des clients",
            li: {
              a: "Comprendre la valeur d'acquisition",
              b: "Harmoniser les conditions de RH après la fusion",
              c: "Évaluation indépendante de l'acquisition",
              d: "Intégration rapide et peu coûteuse",
            },
          },
          ourSolutions: {
            title: "Solutions MC2",
            li: {
              a: "Positionnement de la valeur des solutions technologiques",
              b: "Conseils sur les meilleures pratiques en RH",
              c: "Diligence raisonnable en affaires et TI",
              d: "Bureau de gestion de l'intégration et solution technologique",
            },
          },
          outcome: {
            title: "Résultats",
            listHeading: {
              a: "Confiance accrue",
              b: "Solution concrète",
              c: "Vitesse accélérée",
            },
            listItem: {
              a: "Confort de l'investisseur par rapport à la transaction et aux actifs audités",
              b: "Solutions TI efficaces et intégration rapide",
              c: "Réduction significative du temps d'intégration",
            },
          },
          clientLogosTitle: "Quelques clients que nous avons aidés :",
          clientLogos: ["cowater.jpg", "sogep.jpg", "crcsogema.png", "spb.png"],
        },
        cleanTechAndEngineering: {
          h1: "Technologies Propres & Ingénierie",
          backButton: "Retour à nos secteurs d'industries",
          clientChallenges: {
            title: "Défis des clients",
            li: {
              a: "Besoin d'exécuter des opérations de détourage (carve-outs) multiples et complexes",
              b: "Méconnaissance des coûts et de l'adéquation de l'ERP de haut niveau inclus dans l'accord",
              c: "Besoin d'experts locaux parlant la bonne langue et disposant de réseaux étendus",
              d: "Absence d'experts pour réduire les risques des intégrations complexes et de grande envergure",
            },
          },
          ourSolutions: {
            title: "Solutions MC2",
            li: {
              a: "Plan d’intégration des opérations de détourage (carve-out)",
              b: "Coût total de possession d'un ERP",
              c: "Processus de sélection et gestion des solutions",
              d: "Bureau de gestion de l'intégration",
            },
          },
          outcome: {
            title: "Résultats",
            listHeading: {
              a: "Réseau technique",
              b: "Réduction des coûts",
              c: "Vitesse accélérée",
            },
            listItem: {
              a: "Gestion de la qualité et des coûts des fournisseurs d'intégration tiers",
              b: "Réduction très importante des coûts d'intégration",
              c: "Réduction significative du temps d'intégration",
            },
          },
          clientLogosTitle: "Quelques clients que nous avons aidés :",
          clientLogos: ["englobe.png", "lvm.jpg", "simo.jpg", "energere.png"],
        },
        manufacturing: {
          h1: "Manufacturier  & Industrie 4.0",
          backButton: "Retour à nos secteurs d'industries",
          clientChallenges: {
            title: "Défis des clients",
            li: {
              a: "Besoin de la valeur et des opportunités des actifs informatiques pour négocier un accord",
              b: "David contre Goliath - Besoin d'experts de haut niveau",
              c: "Crise de la croissance et de la gouvernance",
              d: "Absence d'experts pour réduire les risques des intégrations complexes et de grande envergure",
            },
          },
          ourSolutions: {
            title: "Solutions MC2",
            li: {
              a: "Audit informatique pré-fusions-acquisitions",
              b: "Bureau de gestion de l'intégration - Axe de travail SI/TI",
              c: "Conseils en matière de gouvernance et de mise sur le marché",
              d: "Gestion de l'intégration informatique",
            },
          },
          outcome: {
            title: "Résultats",
            listHeading: {
              a: "Coût réduit",
              b: "Confiance",
              c: "Vitesse accélérée",
            },
            listItem: {
              a: "Réduction du prix de la transaction",
              b: "Des investisseurs rassurés et informés",
              c: "Réduction significative du temps d'intégration",
            },
          },
          clientLogosTitle: "Quelques clients que nous avons aidés :",
          clientLogos: ["synergex.jpg", "solmax.png", "gse.jpg", "kinova.png", "ranger.png"],
        },
        financing: {
          h1: "Financement",
          backButton: "Retour à nos secteurs d'industries",
          clientChallenges: {
            title: "Défis des clients",
            li: {
              a: "Besoin de connaître rapidement les coûts et risques liés à la mise en œuvre d'une solution TI",
              b: "Quantifier les synergies et les défis post-investissement",
              c: "Connaître les coûts et les risques de l'intégration globale",
              d: "Absence d’expérience dans les TI complexes de grandes envergures et  de processus d'intégration",
            },
          },
          ourSolutions: {
            title: "Solutions MC2",
            li: {
              a: "Sélection de la solution d'audit réglementaire",
              b: "Audit des processus et de la gouvernance",
              c: "Diligence raisonnable de l'intégration des fusions-acquisitions",
              d: "Bureau de gestion de l'intégration",
            },
          },
          outcome: {
            title: "Résultats",
            listHeading: {
              a: "Confiance accrue",
              b: "Économies significatives",
              c: "Choix",
            },
            listItem: {
              a: "Investisseurs rassurés",
              b: "Réduction des coûts d'intégration",
              c: "Réduction du coût de la conformité",
            },
          },
          clientLogosTitle: "Quelques clients que nous avons aidés :",
          clientLogos: ["desjardins.png", "peak.png"],
        },
        distributionAndLogistics: {
          h1: "Distribution & Logistique",
          backButton: "Retour à nos secteurs d'industries",
          clientChallenges: {
            title: "Défis des clients",
            li: {
              a: "Besoin de connaître rapidement les coûts et risques liés à la mise en œuvre d'une solution informatique",
              b: "Quantifier les synergies et les défis post-investissement",
              c: "Connaître les coûts et les risques de l'intégration globale",
              d: "Aucune expérience en matière de technologie de l’information complexe et de grande envergure et du processus d'intégration informatique",
            },
          },
          ourSolutions: {
            title: "Solutions MC2",
            li: {
              a: "Sélection de la solution d'audit réglementaire",
              b: "Audit des processus et de la gouvernance",
              c: "Diligence raisonnable de l'intégration des fusions-acquisitions",
              d: "Bureau de gestion de l'intégration",
            },
          },
          outcome: {
            title: "Résultats",
            listHeading: {
              a: "Confiance accrue",
              b: "Économies significatives",
              c: "Choix",
            },
            listItem: {
              a: "Investisseurs rassurés",
              b: "Réduction des coûts d'intégration",
              c: "Réduction du coût de conformité",
            },
          },
          clientLogosTitle: "Quelques clients que nous avons aidés :",
          clientLogos: ["bugatti.png", "orthocanada.jpg", "naturestouch.jpg", "cdmv.png", "csc.png", "cpcs.png", "ovation.png", "delta.png", "ship2home.png"],
        },
      },
    },
  };

  //   <IndustryComponent industry={industry} />;

  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  // industryUrl === "technology-software" ? (content = content.industry.technology) : (content = content.industry.services);

  if (industryUrl === "technology-software") {
    content = content.industry.technology;
  } else if (industryUrl === "professional-services") {
    content = content.industry.services;
  } else if (industryUrl === "clean-tech-and-engineering") {
    content = content.industry.cleanTechAndEngineering;
  } else if (industryUrl === "manufacturing-4.0") {
    content = content.industry.manufacturing;
  } else if (industryUrl === "private-vc-and-financial-services") {
    content = content.industry.financing;
  } else if (industryUrl === "distribution-and-logistics") {
    content = content.industry.distributionAndLogistics;
  }
  //   industry === "technology" ? (content = content.industry.technology) : (content = content.industry.services);

  return (
    <div className="industry-page">
      <h1>{content.h1}</h1>
      <Link to="/industries">
        <i class="far fa-arrow-alt-circle-left" /> &nbsp;{content.backButton}
      </Link>

      <div className="">
        <div>
          <h4>{content.clientChallenges.title}</h4>
          <ul>
            <li>{content.clientChallenges.li.a}</li>
            <li>{content.clientChallenges.li.b}</li>
            <li>{content.clientChallenges.li.c}</li>
            <li>{content.clientChallenges.li.d}</li>
          </ul>
        </div>

        <div className="">
          <h4>{content.ourSolutions.title}</h4>
          <ul>
            <li>{content.ourSolutions.li.a}</li>
            <li>{content.ourSolutions.li.b}</li>
            <li>{content.ourSolutions.li.c}</li>
            <li>{content.ourSolutions.li.d}</li>
          </ul>
        </div>

        <div className="">
          <h4>{content.outcome.title}</h4>
          <div className="grid-2">
            <ul>
              <li>{content.outcome.listHeading.a}</li>
              <li className="sub">{content.outcome.listItem.a}</li>
              <li>{content.outcome.listHeading.b}</li>
              <li className="sub">{content.outcome.listItem.b}</li>
              <li>{content.outcome.listHeading.c}</li>
              <li className="sub">{content.outcome.listItem.c}</li>
            </ul>
          </div>
        </div>
        <div className="">
          <h4>{content.clientLogosTitle}</h4>
          <div>
            {content.clientLogos.map((clientLogo, idx) => (
              <img src={`/images/clientLogos/${clientLogo}`} className="logo" key={clientLogo} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryComponent;
