import React from "react";
import BridgeBanner from "../layouts/BridgeBanner";
import ContextAndProblem from "../layouts/ContextAndProblem";
import CustomModal from "../layouts/CustomModal";
import GeneralDescription from "../layouts/GeneralDescription";
import Hero from "../layouts/Hero";
import SocialProof from "../layouts/SocialProof";
import SolutionOverview from "../layouts/SolutionOverview";
import SolutionTimeline from "../layouts/SolutionTimeline";

const Home = (props) => {
  let languageStoredInLocalStorage = localStorage.getItem("language");

  // let content = {
  //   hero: {
  //     english: {
  //       title: "De-risk your most important transactions",
  //       description: "DEAL READINESS | EVALUATION | INTEGRATION MOBILIZATION FOR MERGERS & ACQUISITIONS",
  //     },
  //     french: {
  //       title: "Éliminez les risques liés à vos transactions les plus importantes",
  //       description: "PRÉPARATION À LA TRANSACTION | ÉVALUATION | MOBILISATION DE L'INTÉGRATION POUR LES FUSIONS-ACQUISITIONS",
  //     },
  //   },
  //   generalDescription: {
  //     english: {},
  //     french: {},
  //   },
  // };
  let content = {
    english: {
      hero: {
        title: "De-risk your most important transactions",
        description: ['DEAL READINESS ',<span className='vertical-bar-style'>|</span>,' EVALUATION ', <span className='vertical-bar-style'>|</span>,' INTEGRATION MOBILIZATION FOR MERGERS & ACQUISITIONS'],
      },
      generalDescription: {
        h2: {
          a: "Deal Readiness",
          b: "Integration Assistance",
        },
        span: {
          a: "ATTRACT INTEREST, EXPRESS CONFIDENCE AND GENERATE VALUE",
          b: "ACCELERATE INTEGRATION, REDUCE RISKS AND MAXIMIZE VALUE",
        },
        p: {
          a: "Our assessment and deal preparation to quickly enable and keep shareholder value to the end. Lower impact on your daily operations while supporting your strategic objectives and goals.",
          b: "Our past experience, our proven methods, and tools with successful project delivery experience at the world’s largest consulting firms ensure that your M&A, IT or business improvement project will succeed.",
        },
      },
      // contextAndProblem: {},
      solutionOverview: {
        h2: "M&A Services",
        h4: {
          a: "Pre-M&A Process & Technology Due Diligence​",
          b: "Integration setup and planning",
          c: "Integration management​",
          d: "Integration management and functional work stream delivery​ ",
        },
        p: {
          a: "To help you understand process and technology assets & risks and decrease integration risks and costs, speed up integration & reach transaction objectives​",
          b: "To help you to quickly drive vision and approach, integration management office (IMO) setup and governance. High-level integration plan ready for internal teams to implement​",
          c: "Integration leadership and IMO resourcing throughout your integration, leading internal teams to stay on track​",
          d: "Comprehensive integration management, including IMO and support for internal resources in some or all functional work streams​",
        },
      },
      bridgeBanner: {
        title: "successful merger & acquisition projects",
        subtitle: "Business and tech intelligence for M&A projects",
        description:
          "We provide the independent and professional value you expect. Our goal is to help you meet yours, our proactive approach is the number one quality our clients appreciate from us.",
        button: "Our Team",
      },
      solutionTimeline: {
        title: "We accompany our clients in M&A value-added services",
        graphBars: [
          "Functional Charters",
          "Organization design and appointments",
          "As-is processes and systems",
          "End-state processes and systems",
          "Integration Plans and Project Definition",
          "Synergy validation and planning",
        ],
        button: "You have a question?"
      },
      socialProof: {
        title: "Clients that trust us",
        button: "View Our Industries",
      },
    },
    french: {
      hero: {
        title: "Éliminez les risques liés à vos transactions les plus importantes",
        description: "PRÉPARATION À LA TRANSACTION | ÉVALUATION | MOBILISATION DE L'INTÉGRATION POUR LES FUSIONS-ACQUISITIONS",
      },
      generalDescription: {
        h2: {
          a: "Préparation à la transaction",
          b: "Assistance à l'intégration",
        },
        span: {
          a: "SUSCITER L'INTÉRÊT, INSPIRER LA CONFIANCE ET GÉNÉRER DE LA VALEUR",
          b: "ACCÉLÉRER L'INTÉGRATION, RÉDUIRE LES RISQUES ET MAXIMISER LA VALEUR",
        },
        p: {
          a: "Notre évaluation et notre préparation à la transaction permettent de générer rapidement de la valeur pour les actionnaires et de la conserver jusqu'au bout. Réduisez l'impact sur vos opérations quotidiennes tout en soutenant vos objectifs et buts stratégiques.",
          b: "Notre expérience passée, nos méthodes et outils éprouvés, ainsi que notre expérience de projets réussis dans les plus grandes sociétés de conseil du monde, garantissent la réussite de votre projet de fusion-acquisition, de technologie  ou d'amélioration des affaires.",
        },
      },
      // contextAndProblem: {},
      solutionOverview: {
        h2: "Services en fusion-acquisition",
        h4: {
          a: "Préparation de la transaction",
          b: "Mise en place et planification de l'intégration",
          c: "Gestion de l’intégration",
          d: "Gestion de l'intégration et livraison des départements fonctionnels",
        },
        p: {
          a: "Mettre en place une atténuation durable des risques pendant la période précédant la transaction, adapter les processus de préparation qui maintiendront une valeur de transaction maximisée. Investir dans quelques structures clés et s'assurer que les investisseurs reconnaîtront la valeur et réduiront les frais de transaction",
          b: "Pour vous aider à concrétiser rapidement l’approche d’intégration et définir la vision future. Mise en place et gouvernance du bureau d’intégration (IMO). Plans d'intégration prêts à être mis en œuvre par les équipes internes",
          c: "Leadership en matière d'intégration et affectation des ressources du IMO (bureau d’intégration) tout au long de l’intégration, en menant  les équipes internes à rester sur la bonne voie​",
          d: "Gestion complète de l'intégration, y compris le IMO (bureau d’intégration) et le soutien aux ressources internes dans certains ou tous les départements fonctionnels",
        },
      },
      // solutionOverview: {
      //   h2: "Services en fusion-acquisition",
      //   h4: [
      //     "Préparation de la transaction",
      //     "Mise en place et planification de l'intégration",
      //     "Gestion de l’intégration ",
      //     "Gestion de l'intégration et livraison des départements fonctionnels",
      //   ],
      // },
      bridgeBanner: {
        title: "projets de fusion-acquisition réussis",
        subtitle: "Intelligence d'affaires et technologique pour les projets de fusion-acquisition",

        description: "Nous apportons la valeur indépendante et professionnelle que vous attendez. Notre objectif est de vous aider à atteindre le vôtre, notre approche proactive est la qualité numéro un que nos clients apprécient chez nous.",
        button: "Notre équipe",
      },
      solutionTimeline: {
        title: "Nous accompagnons nos clients dans les services à forte valeur ajoutée de fusion-acquisition",
        graphBars: [
          "Cahier de charges fonctionnel",
          "Design organisationnel et affectations",
          "Processus et systèmes tels quels",
          "Processus et systèmes finaux",
          "Plans d'intégration et définition du projet",
          "Validation et planification de la synergie",
        ],
        button: "Vous avez une question?",
      },
      socialProof: {
        title: "Des clients qui nous font confiance",
        button: "Nos secteurs d’activité",
      },
    },
  };

  // props.language === "English" ? (content = content.English) : (content = content.French);
  languageStoredInLocalStorage === "English" ? (content = content.english) : (content = content.french);

  return (
    <div>
      <CustomModal />
      <Hero content={content.hero} />
      <GeneralDescription content={content.generalDescription} />
      {/* <ContextAndProblem content={content.contextAndProblem} /> */}
      <SolutionOverview content={content.solutionOverview} />
      <BridgeBanner content={content.bridgeBanner} />
      <SolutionTimeline content={content.solutionTimeline} />
      <SocialProof content={content.socialProof} />
    </div>
  );
};

export default Home;
